import mockupQuillbot from "../../assets/journalImages/QuillBot/mockup.png";
import mockupYono from "../../assets/journalImages/Yono/mockup.png";
import mockupNft from "../../assets/journalImages/NFT/mockup.png";

export const blogs = [
  {
    id: "1",
    title: "Case Study: Quillbot Mobile App",
    link: "/ux-quillbot-case-study",
    imgLink: mockupQuillbot,
    imgAlt: "Case Study: Quillbot Mobile App",
    categoryTitle: "UX Research",
    categoryLink: "/ux-research",
  },
  {
    id: "2",
    title: "Case Study : Redesigning a Mobile Banking App",
    link: "/ux-yono-case-study",
    imgLink: mockupYono,
    imgAlt: "Case Study : Redesigning a Mobile Banking App",
    categoryTitle: "UX Research",
    categoryLink: "/ux-research",
  },
  {
    id: "3",
    title: "NFT Marketplace: UX Case Study",
    link: "/ux-nft-case-study",
    imgLink: mockupNft,
    imgAlt: "NFT Marketplace: UX Case Study",
    categoryTitle: "UX Research",
    categoryLink: "/ux-research",
  },
];
