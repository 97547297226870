export const galleryMenudata = [
    {
        id: "1",
        title: "All",
        filter: "*"
    },
    {
        id: "2",
        title: "UI Design",
        filter: "ui"
    },
    {
        id: "3",
        title: "Product",
        filter: "product"
    }
];
